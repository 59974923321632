import * as React from "react";
import { Button } from "../../ui-kit/Button/Button";
import { Row } from "../../ui-kit/Grid/Row";
import { Heading } from "../../ui-kit/Typography/Heading";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import cn from "classnames";
import { Col } from "../../ui-kit/Grid/Col";
import { useWindowSize } from "../../utils/useWindowSize";
import { Container } from "../../ui-kit/Container/Container";
import { SwiperLayout } from "../../ui-kit/Swiper/SwiperLayout";

export const SellersPage = () => {
	const openSellerRegForm = () => {
		const win = window.open("https://market.atips.io/sign_up", "_blank");
		win?.focus();
	};

	const { isTablett, isTablet } = useWindowSize();
	return (
		<main className="main-home">
			<div className='sellers-page mt-80 T15-bold'>
				<Row className='sellers-page__top-section mb-200 mb-m-64'>
					<Container className=' w100'>
						<div className='sellers-page__top-section-left'>
							<Heading
								level={isTablet ? 2 : 1}
								text='Sell more with Atips features'
							/>
							<h5
								className={cn("mv-32 mv-m-16", {
									H5: !isTablet,
								})}>
								As an advertiser, you can easily launch an advertising campaign for your product in the Atips environment
							</h5>

							{/* <Row middle cGap={24}>
								<Button
									text='Register'
									variant='primary'
									onClick={openSellerRegForm}
									className={cn({ "mb-m-16": isTablet })}
								/>

								<p
									className={cn("opacity-7", {
										"T-center T13-list": isTablet,
										"T14-list": !isTablet,
									})}>
									All you need to do is register<br />an advertiser account — it’s free
								</p>
							</Row> */}
						</div>
					</Container>
					<img
						className='sellers-page__top-section-right'
						src={`/images/sellers/top-section-img.png`}
						alt='atips'
						style={isTablet ? { left: "-40px", right: "-40p", width: "calc(100% + 80px)" } : {
							width: "688px",
							height: "688px"
						}}
					/>
				</Row>
				<Container>
					<section className='sellers-page__how-section mb-120 mb-m-64'>
						<Heading className='mb-40' level={3}>
							How to launch
							<br /> an advertising campaign
						</Heading>
						{isTablett && (
							<SwiperLayout
								params={{
									spaceBetween: 16,
									slidesPerView: 1,
									navigation: false,
									pagination: {
										el: ".custom-pagination",
										clickable: true,
									},
								}}>
								{[
									"Take two minutes to register",
									"Select an advertisement for the product to advertise",
									"Fill out a compact form to choose a target audience",
									"Pick the genres of films, TV shows or blog content serving your goals",
									"Click the Launch Ad button",
								].map((item, i) => (
									<Col
										xs='auto'
										as='li'
										className='radius-24 bg-200 p-32  pb-m-24 pr-m-24'
										between
										key={i}>
										<h1>
											<p>{`0${i + 1}`}</p>
											<span></span>
										</h1>
										<p>{item}</p>
									</Col>
								))}
							</SwiperLayout>
						)}
						{!isTablett && (
							<Row as='ul' cGap={16} nowrap between>
								{[
									"Take two minutes to register",
									"Select an advertisement for the product to advertise",
									"Fill out a compact form to choose a target audience",
									"Pick the genres of films, TV shows or blog content serving your goals",
									"Click the Launch Ad button",
								].map((item, i) => (
									<Col
										xs='auto'
										as='li'
										className='radius-24 bg-200 p-32'
										rGap={80}
										between
										key={i}>
										<h1>
											<p>{`0${i + 1}`}</p>
											<span></span>
										</h1>
										<p>{item}</p>
									</Col>
								))}
							</Row>
						)}
					</section>
					<section className='sellers-page__how-work-section mb-120 mb-m-64'>
						<Heading
							className='mb-64 mb-m-32'
							text='How it works'
							level={3}
						/>

						<Row as='ul' cGap={24} rGap={32} between>
							{[
								"An advertising campaign is launched. Its data arerecordedin real time.",
								"Conversion into clicks and purchases occurs",
								"You control the process since the advertising campaign can be stopped at any time",
								"All statistics are saved in real time",
							].map((item, i) => (
								<Col middle start rGap={8} className='T-center' as='li' key={i}>
									<img
										src={`/images/sellers/how-work-img-${i + 1}.svg`}
										alt='atips'
									/>
									<img
										src={`/images/sellers/how-work-bg-${i + 1}.png`}
										alt='atips'
									/>
									<p
										dangerouslySetInnerHTML={{
											__html: item,
										}}
									/>
								</Col>
							))}
						</Row>
					</section>

					<section className='sellers-page__you-get-section mb-80 mb-m-48'>
						<Heading
							className='mb-40 mb-m-32'
							level={3}
							text='What you get'
						/>
						<ResponsiveMasonry
							columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 3, 1024: 3 }}>
							<Masonry gutter={isTablett ? 14 : 24}>
								{[
									"New tool for product promotionа",
									"New format of interaction with the audience",
									"Easy to use and intuitive service",
									"Additional conversion from impulse purchases",
									"Detailed conversion statistics (by age, gender, and region)",
									"Possibility of advertising with low budgets",
								].map((item, i) => (
									<li className='flex1 radius-24 ph-32 pt-32 bg-200' key={i}>
										<p
											className={cn({
												"mb-70": (i + 1) % 2 !== 0 && !isTablett,
												"mb-8": isTablett || (i + 1) % 2 === 0,
											})}
											dangerouslySetInnerHTML={{
												__html: item,
											}}
										/>
										<img
											src={`/images/sellers/you-get-${i + 1}.svg`}
											alt='atips'
										/>
									</li>
								))}
							</Masonry>
						</ResponsiveMasonry>
					</section>
				</Container>
			</div>
		</main>
	);
};
