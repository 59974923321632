import * as React from "react";
import { FC } from "react";

import { Logo } from "../Logo/Logo";


export const FOOTER_SOCIALS = [
	{
		icon: <svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M12.8931 19C6.0599 19 2.1624 14.4955 2 7H5.42286C5.53529 12.5015 8.05865 14.8318 10.0574 15.3123V7H13.2805V11.7447C15.2543 11.5405 17.3277 9.37838 18.0273 7H21.2503C20.7132 9.93093 18.4646 12.0931 16.8656 12.982C18.4646 13.7027 21.0256 15.5886 22 19H18.4521C17.6901 16.7177 15.7914 14.952 13.2805 14.7117V19H12.8931Z'
				fill='white'
			/>
		</svg>,
		href: "https://vk.com/atips",
	},
	{
		icon: <svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M17.6849 14.111C17.0659 14.7467 16.3223 15.2073 15.4955 15.528C14.7139 15.8296 13.8576 15.9828 13.0104 16.0856C13.1379 16.2236 13.198 16.293 13.2783 16.373C14.4294 17.5301 15.5851 18.6816 16.7315 19.8425C17.1223 20.2374 17.2045 20.7284 16.9893 21.19C16.7537 21.6915 16.2271 22.0226 15.7107 21.9865C15.3837 21.9636 15.1278 21.8009 14.9015 21.5735C14.0331 20.698 13.1481 19.8387 12.2972 18.949C12.0497 18.6901 11.9314 18.7396 11.7125 18.9632C10.8386 19.8625 9.95078 20.7503 9.05839 21.6315C8.65653 22.0274 8.17985 22.0987 7.71425 21.8723C7.22001 21.6315 6.90592 21.1272 6.92994 20.62C6.94657 20.2764 7.11562 20.0138 7.35119 19.7797C8.48932 18.6406 9.6256 17.5006 10.7619 16.3597C10.8367 16.2854 10.9069 16.2045 11.0159 16.0865C9.46671 15.9257 8.06992 15.5432 6.87451 14.6059C6.72578 14.4907 6.57243 14.3775 6.43755 14.249C5.91375 13.7447 5.8611 13.168 6.27496 12.5732C6.6297 12.0679 7.22463 11.93 7.84266 12.2202C7.96275 12.2792 8.07638 12.3487 8.18539 12.4257C10.4145 13.9588 13.476 14.0006 15.7135 12.4943C15.9352 12.3239 16.1726 12.1859 16.447 12.1146C16.9809 11.9766 17.4789 12.1755 17.7653 12.6399C18.0923 13.1756 18.0886 13.6961 17.6849 14.111ZM11.9601 12.1231C9.19603 12.1136 6.92994 9.82027 6.94657 7.05016C6.96227 4.24865 9.23021 1.99146 12.021 2.00002C14.8174 2.00669 17.0622 4.29623 17.0484 7.12629C17.0336 9.89068 14.7518 12.1327 11.9601 12.1231ZM12.0007 4.58362C10.6178 4.58362 9.5129 5.69699 9.52399 7.08632C9.53415 8.45662 10.6381 9.5443 12.0136 9.53764C13.3846 9.53383 14.4765 8.43093 14.4719 7.05587C14.4673 5.68081 13.3726 4.58552 12.0007 4.58362Z'
				fill='white'
			/>
		</svg>,
		href: "https://ok.ru/group/70000001541047",
	},
	{
		icon: <svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				fill-rule='evenodd'
				clip-rule='evenodd'
				d='M2.33559 11.1514L6.71358 12.8306L8.40812 18.4309C8.51656 18.7896 8.94335 18.9222 9.22666 18.6842L11.667 16.6397C11.9228 16.4255 12.2872 16.4149 12.5545 16.6143L16.9561 19.8983C17.2591 20.1246 17.6884 19.9539 17.7644 19.5776L20.9888 3.63907C21.0718 3.22801 20.6787 2.8851 20.2978 3.03647L2.33046 10.1594C1.88706 10.3351 1.89095 10.9802 2.33559 11.1514ZM8.13503 11.9368L16.6912 6.52129C16.845 6.42423 17.0032 6.63791 16.8712 6.76379L9.80982 13.5091C9.56161 13.7465 9.4015 14.0643 9.35615 14.4092L9.11563 16.241C9.08377 16.4856 8.74941 16.5099 8.68376 16.2732L7.75865 12.9327C7.65268 12.5517 7.80708 12.1448 8.13503 11.9368Z'
				fill='white'
			/>
		</svg>,
		href: "https://t.me/atips_global",
	},
];

export const FOOTER_NAV_ITEMS = [
	{
		label: "About the project",
		pathname: "/about",
		key: "about",
	},
	{
		label: "For advertisers",
		pathname: "/sellers",
		key: "sellers",
	},
	{
		label: "For creators",
		pathname: "/creators",
		key: "creators",
	},
];


type FooterProps = {
	[prop: string]: any;
};

export const Footer: FC<FooterProps> = () => {
	return (
		<footer>
			<div className='footer-columns'>
				<div className='footer-column'>
					<Logo width='119' />
				</div>
				<div className='footer-column'>
					{FOOTER_NAV_ITEMS.slice(0, 3).map((item, i) => (
						<a href={item.pathname} key={i}>
							{item.label}
						</a>
					))}
				</div>
				<div className='footer-column'>
					{FOOTER_NAV_ITEMS.slice(3, 6).map((item, i) => (
						<a href={item.pathname} key={i}>
							{item.label}
						</a>
					))}
				</div>
				<div className='footer-column contacts-column'>
					<div className='footer-social-links'>
						{FOOTER_SOCIALS.map(({ icon, href }, i) => (
							<a href={href} key={i} target='_blank' rel='noopener noreferrer'>
								{icon}
							</a>
						))}
					</div>
				</div>
			</div>
			<div className='footer-copyright'>
				© 2023 Atips
			</div>
		</footer>
	);
};
