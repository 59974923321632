import * as React from "react";
import cn from "classnames";
import { ButtonHTMLAttributes, createElement, FC } from "react";
import { RadiusSize, SvgProps } from "../../types/shared"

export const enum BtnVariantEnum {
	primary = "primary",
	secondary = "secondary",
}

//                height: xss  xs-40  s-48  m-56  l-64  xl-72
export type BtnSize = "none" | "xss" | "xs" | "s" | "m" | "l" | "xl";
export type BgType = "white-opacity" | "pink" | "200" | 200;

export type IconBtnProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	icon: React.ReactNode;
	isRound?: boolean;
	opacity?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
	noOpacityOnHover?: boolean;
	bg?: BgType | boolean;
	svgProps?: SvgProps;
	className?: string;

	size?: BtnSize;
	children?: any;
	as?: string;

	textAfter?: any;
	textBefore?: any;

	radius?: RadiusSize;
};

export const IconButton: FC<IconBtnProps> = (props) => {
	const {
		icon,
		svgProps = {},
		size = "m",
		className,
		isRound,
		opacity,
		bg,
		as,
		textAfter = "",
		textBefore = "",
		radius,
		noOpacityOnHover,
		...rest
	} = props;

	const baseClass = "icon-btn";


	const classNames = cn(baseClass, className, {
		[`${baseClass}--${size}`]: size,
		[`round`]: isRound,
		[`${baseClass}--opacity`]: opacity,
		[`bg--${bg} bg-${bg}`]: bg,
	});

	if (as) {
		return createElement(
			as,
			{
				className: classNames,
				...rest,
			});
	}
	return (
		<button
			className={cn(baseClass, className, {
				[`${baseClass}--${size}`]: size,
				[`round`]: isRound,
				[`${baseClass}--opacity`]: opacity,
				[`bg--${bg} bg-${bg}`]: bg,
				[`radius-${radius}`]: radius,
				"opacity-hover": noOpacityOnHover,
			})}
			{...rest}>
			{textBefore}
			{icon}
			{textAfter}
		</button>
	);
};
