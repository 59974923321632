import {useEffect} from "react";

type useEventListenerProps = {
	target: "window" | "document" | undefined;
	event: string;
	callback: EventListenerOrEventListenerObject;
};
export const useEventListener = (props: useEventListenerProps): void => {
	const {target, event, callback} = props;

	useEffect(() => {
		const targetObject = {
			window: window,
			document: document,
		}[target || "window"];

		targetObject.addEventListener(event, callback);
		return () => targetObject.removeEventListener(event, callback);
		// trigger && t.dispatchEvent(new Event(event));
	});
};
