import * as React from "react"
import { useWindowSize } from "../utils/useWindowSize";
import { SwiperLayout } from "../ui-kit/Swiper/SwiperLayout";
import cn from "classnames";
import { Button } from "../ui-kit/Button/Button";
import { Container } from "../ui-kit/Container/Container";
import { Col } from "../ui-kit/Grid/Col";
import { Row } from "../ui-kit/Grid/Row";
import { Heading } from "../ui-kit/Typography/Heading";

export const MainPage = () => {
    const imgPath = "/images/about";
    const openSellerRegForm = () => {
        const win = window.open("https://creator.atips.io/sign_up", "_blank");
        win?.focus();
    };

    const { isTablet, isWidth1024 } = useWindowSize();

    const cabinetSectionItems = [
        "A viewer visits our streaming service",
        "Picks and watches their favorite bloggers, movies,</br>and TV series for free",
        "During the broadcast, tips appear, i.e. interactive advertising messages with links to products identical to those shown in the frame.",
    ];

    const possibilitiesSectionItems = [
        "A unique platform for distributing videos of all formats",
        "Possibility to monetize content",
        "Regular and transparent reports on projects",
    ];
    return (
        <main className="main-about" style={{ overflowX: "hidden" }}>
            <div className="sellers-page creators-page about-page mt-80 T15-bold">
                <Row className="sellers-page__top-section mb-180 mb-m-56">
                    <Container className="w100">
                        <div className="sellers-page__top-section-left">
                            <Heading level={isTablet ? 2 : 1} text="About the Project" />
                            <h5
                                className={cn("mv-32 mv-m-16", {
                                    H5: !isTablet,
                                })}
                            >
                                Atips is a unique interactive platform with thousands of hours of movies, series, video blog content, information tips, and ads in the form of pop-up windows that appear during the broadcast.
                            </h5>

                            {/* <Button
								text='Зарегистрироваться'
								variant='primary'
								onClick={openSellerRegForm}
								className={cn({"mb-m-16": isTablet})}
							/> */}
                        </div>
                    </Container>
                    <img
                        className="sellers-page__top-section-right"
                        src={`${imgPath}/top-section-img.png`}
                        alt="atips"
                        style={isTablet ? { left: "-40px", right: "-40p", width: "calc(100% + 80px)" } : {}}
                    />
                </Row>

                {/* <div className='about-page--bg'> */}
                {/* КАК ЭТО РАБОТАЕТ */}
                <section className="creators-page__cabinet-section pb-160 pb-m-24">
                    <Container>
                        <Heading level={3} text="How it works" className="mb-40 mb-m-32" />

                        {cabinetSectionItems.map((item, i) => (
                            <li key={i} className={`point-${i + 1}`}>
                                <img src={`${imgPath}/how-work-${i + 1}.png`} alt="atips" />

                                <h5
                                    className={cn({
                                        "mb-40": i !== 4,
                                    })}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                />
                            </li>
                        ))}
                    </Container>
                </section>

                {/* РОЗОВЫЙ И ЖЕЛТЫЙ БЛОК */}
                <Container>
                    <section className="about-page__video-section mb-120 mb-m-64">
                        <div className="about-page__video-section__block">
                            <Col between className="h100">
                                <div className="pr-48 pr-m-24 mb-40 mb-m-16 zIndex-1">
                                    <Heading text="It's simple!" level={2} className="mb-24 mb-m-8" />
                                    <Heading
                                        text="If the viewer wants to learn more about a product or buy it, then they simply click on the tip and visit the target website."
                                        level={5}
                                        className="about-page__video-section__block-subtitle"
                                    />
                                </div>
                                <img
                                    alt="tips-img"
                                    src={`${imgPath}/video-section-block-img-${isTablet ? "mob-" : ""}1.png`}
                                />
                                {/* video-section-block-img-1 */}
                            </Col>
                        </div>
                        <div className="about-page__video-section__block"></div>
                    </section>
                </Container>
                {/* </div> */}
                <Container>
                    {/* ЧТО ПОЛУЧАЕТ ЗРИТЕЛЬ */}
                    <section className="sellers-page__how-work-section mb-120 mb-m-64">
                        <Heading className="mb-64 mb-m-32" text="What the viewer gets" level={3} />

                        <Row as="ul" cGap={24} rGap={32} between>
                            {[
                                "Free broadcast not interrupted with ads",
                                "User-friendly interactive player",
                                "Thousands of hours of various content from short videos to full-length films",
                                "Unique service of information tips",
                                "Community of the most popular bloggers and influencers",
                            ].map((item, i) => (
                                <Col middle end rGap={16} className="T-center" as="li" key={i}>
                                    <img src={`${imgPath}/viewer-get-img-${i + 1}.svg`} alt="atips" />
                                    <img src={`${imgPath}/viewer-get-bg-${i + 1}.svg`} alt="atips" />
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: item,
                                        }}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </section>
                </Container>

                {/* ЧТО ПОЛУЧАЕТ ПРОДАВЕЦ */}
                <section className="about-page__seller-section mb-120 mb-m-64">
                    <Container>
                        <Row between middle className="mb-64 mb-m-32">
                            <Heading text="What the seller gets?" level={3} />
                            {!isTablet && (
                                <a className="about-page__more-btn" href="/sellers">
                                    <Button text="Find out more" size="s" variant="primary" />
                                </a>
                            )}
                        </Row>
                    </Container>

                    <SwiperLayout
                        params={{
                            spaceBetween: isWidth1024 ? 16 : 24,
                        }}
                    >
                        {[
                            "Opportunity to easily launch an advertising campaign for a product or service",
                            "New tool for product or project promotion",
                            "Unique format of interaction with the audience",
                            "Easy to use and intuitive service",
                            "Additional conversion from impulse purchases",
                            "Detailed conversion statistics (by age, gender, location)",
                            "Possibility of advertising with low budgets",
                            "Service for compliance with current advertising laws",
                            "Payment per click and not per view",
                        ].map((text, i) => (
                            <div className={cn("about-page__seller-section__list-item")} key={i}>
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: text,
                                    }}
                                />
                                <img src={`${imgPath}/seller-get-slide-img-${i + 1}.png`} />
                            </div>
                        ))}
                    </SwiperLayout>
                    {isTablet && (
                        <Container>
                            <a className="about-page__more-btn" href="/sellers">
                                <Button text="Find out more" size="s" variant="primary" />
                            </a>
                        </Container>
                    )}
                </section>

                {/* ЧТО ПОЛУЧАЕТ КРЕАТОР */}
                <section className="about-page__creators-section mb-120 mb-m-64">
                    <Container>
                        <Row between middle className="mb-64 mb-m-32">
                            <Heading text="What the creator gets" level={3} />
                            {!isTablet && (
                                <a className="about-page__more-btn" href="/creators">
                                    <Button text="Find out more" size="s" variant="primary" />
                                </a>
                            )}
                        </Row>
                    </Container>
                    {isWidth1024 ? (
                        <SwiperLayout
                            params={{
                                spaceBetween: isWidth1024 ? 16 : 24,
                            }}
                        >
                            {[
                                "Possibility to upload a finished video",
                                "Complete freedom of expression and content management",
                                "Individual advertising suggests",
                                "Additional source of content monetization",
                                "Detailed statistics for analyzing the resulting data",
                            ].map((text, i) => (
                                <div key={i}>
                                    <Row
                                        bottom
                                        className="about-page__creators-section__list-item"
                                        style={{
                                            backgroundImage: `url("${imgPath}/creator-get-${i + 1}.png")`,
                                        }}
                                    >
                                        <p>{text}</p>
                                    </Row>
                                </div>
                            ))}
                        </SwiperLayout>
                    ) : (
                        <Container>
                            <Row className="about-page__creators-section__list">
                                {[
                                    "Possibility of advertising with low budgets",
                                    "Complete freedom of expression and content management",
                                    "Individual advertising suggests",
                                    "Additional source of content monetization",
                                    "Detailed statistics for analyzing the resulting data",
                                ].map((text, i) => (
                                    <Row
                                        bottom
                                        className="about-page__creators-section__list-item"
                                        style={{
                                            backgroundImage: `url("${imgPath}/creator-get-${i + 1}.png")`,
                                        }}
                                        key={i}
                                    >
                                        <p>{text}</p>
                                    </Row>
                                ))}
                            </Row>
                        </Container>
                    )}
                    {isTablet && (
                        <Container>
                            <a className="about-page__more-btn" href="/creators">
                                <Button text="Find out more" size="s" variant="primary" />
                            </a>
                        </Container>
                    )}
                </section>
                {/* ЧТО ПОЛУЧАЕТ ПРАВООБЛАДАТЕЛЬ				 */}
                <Container>
                    <section className="creators-page__capabilities-section mb-80 mb-m-48">
                        <Heading level={3} text="What the copyright holder gets" className="mb-40 mb-m-32" />

                        <Row rGap={16} cGap={24} fullWidth>
                            {possibilitiesSectionItems.map((item, i) => (
                                <li className="" key={i}>
                                    <p className="mh-32 mt-32 mb-8">{item}</p>
                                    <div className="figure-wrap">
                                        <img
                                            src={`${imgPath}/capabilities-${i + 1}${isTablet ? "" : ""}.png`}
                                            alt="atips"
                                        />
                                    </div>
                                </li>
                            ))}
                        </Row>
                    </section>
                </Container>
            </div>
        </main>
    );
};

