import * as React from "react"
import { FC } from "react";
import cn from "classnames";

type SwiperArrowBtnProps = {
	isNext?: boolean;

	[prop: string]: any;
};

export const SwiperArrowBtn: FC<SwiperArrowBtnProps> = (props = {}) => {
	const { isNext, ...rest } = props;
	// console.log(rest);
	// console.log("SWIPER BTN2", rest);

	return (
		<button
			className={cn("slider-control-arrow", "swiper-arrow-btn", {
				"slider-next swiper-arrow-btn_next": isNext,
				"slider-prev swiper-arrow-btn_prev": !isNext,
			})}
			{...rest}>
			{/* <img src='/static/img/arrow.svg' alt='arrow' /> */}

			{isNext ? (
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M7.58594 19.9998L8.29304 19.2927L15.5859 11.9998L8.29304 4.70695L7.58594 3.99985L9.00015 2.58563L9.70726 3.29274L17.7073 11.2927C18.0978 11.6833 18.0978 12.3164 17.7073 12.707L9.70726 20.707L9.00015 21.4141L7.58594 19.9998Z'
						fill='white'
					/>
				</svg>
			) : (
				<svg
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M16.4142 3.99985L15.7071 4.70695L8.41421 11.9998L15.7071 19.2927L16.4142 19.9998L15 21.4141L14.2929 20.707L6.29289 12.707C5.90237 12.3164 5.90237 11.6833 6.29289 11.2927L14.2929 3.29274L15 2.58563L16.4142 3.99985Z'
						fill='white'
					/>
				</svg>
			)}
		</button>
	);
};
