import * as React from "react";
import { FC, CSSProperties } from "react";
import cn from "classnames";

type ContainerProps = {
	[prop: string]: any;
	style?: CSSProperties;
};

export const Container: FC<ContainerProps> = ({
	children,
	className,
	style = {},
}) => {
	return (
		<div className={cn(`container`, className)} style={style}>
			{children}
		</div>
	);
};
