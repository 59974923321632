// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/AvertaCY-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/AvertaCY-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("fonts/AvertaCY-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("fonts/AvertaCY-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("fonts/AvertaCY-Semibold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("fonts/AvertaCY-Semibold.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
	font-family: "Averta CY";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("woff2"),
		url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Averta CY";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format("woff2"),
		url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Averta CY";
	src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format("woff2"),
		url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
`, "",{"version":3,"sources":["webpack://./public/fonts.css"],"names":[],"mappings":"AAAA;CACC,wBAAwB;CACxB;wDACkD;CAClD,mBAAmB;CACnB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,wBAAwB;CACxB;wDAC+C;CAC/C,iBAAiB;CACjB,kBAAkB;CAClB,kBAAkB;AACnB;;AAEA;CACC,wBAAwB;CACxB;wDACmD;CACnD,gBAAgB;CAChB,kBAAkB;CAClB,kBAAkB;AACnB","sourcesContent":["@font-face {\r\n\tfont-family: \"Averta CY\";\r\n\tsrc: url(\"fonts/AvertaCY-Regular.woff2\") format(\"woff2\"),\r\n\t\turl(\"fonts/AvertaCY-Regular.woff\") format(\"woff\");\r\n\tfont-weight: normal;\r\n\tfont-style: normal;\r\n\tfont-display: swap;\r\n}\r\n\r\n@font-face {\r\n\tfont-family: \"Averta CY\";\r\n\tsrc: url(\"fonts/AvertaCY-Bold.woff2\") format(\"woff2\"),\r\n\t\turl(\"fonts/AvertaCY-Bold.woff\") format(\"woff\");\r\n\tfont-weight: bold;\r\n\tfont-style: normal;\r\n\tfont-display: swap;\r\n}\r\n\r\n@font-face {\r\n\tfont-family: \"Averta CY\";\r\n\tsrc: url(\"fonts/AvertaCY-Semibold.woff2\") format(\"woff2\"),\r\n\t\turl(\"fonts/AvertaCY-Semibold.woff\") format(\"woff\");\r\n\tfont-weight: 600;\r\n\tfont-style: normal;\r\n\tfont-display: swap;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
