import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import cn from "classnames";
import {
	ReactIdSwiperChildren,
	ReactIdSwiperProps,
	ReactIdSwiperRenderProps,
} from "react-id-swiper/lib/types";
import SwiperCore, {
	Navigation,
	EffectCoverflow,
	Autoplay,
	Pagination,
	EffectCube,
	Parallax,
	Keyboard,
	Mousewheel,
} from "swiper";

import { SwiperArrowBtn } from "./SwiperArrowBtn";
import { useWindowSize } from "../../utils/useWindowSize";
import DynamicSwiper from "react-id-swiper";

type SwiperLayoutProps = {
	children?: ReactIdSwiperChildren | undefined;
	params?: ReactIdSwiperProps | ReactIdSwiperRenderProps;

	[prop: string]: any;
};

SwiperCore.use([
	Navigation,
	Autoplay,
	Pagination,
	Parallax,
	Keyboard,
	Mousewheel,
]);
export const SwiperLayout: FC<SwiperLayoutProps> = (props) => {
	const { children, params = {} } = props;
	const { isMobile } = useWindowSize();
	const swiperRef = useRef(null);

	const [swiper, setSwiper] = useState(null);
	const defaultParams: ReactIdSwiperProps = {
		// preventInteractionOnTransition: true,

		spaceBetween: isMobile ? 8 : 24,
		shouldSwiperUpdate: true,
		rebuildOnUpdate: false,
		slidesPerView: "auto",
		navigation: {
			nextEl: ".slider-next",
			prevEl: ".slider-prev",
		},
		// observer: true,
		// observeParents: true,
		renderPrevButton: (props) => <SwiperArrowBtn {...props} />,
		renderNextButton: (props) => <SwiperArrowBtn isNext {...props} />,
		ContainerEl: undefined,
		runCallbacksOnInit: true,
		//@ts-ignore
		// on: {
		// 	init: (swiper) => {
		// 		console.log("init", swiper);
		// 		setSwiper(swiper);
		// 	},
		// 	// transitionStart: (swiper) =>
		// 	// 	swiper &&
		// 	// 	setTimeout(() => {
		// 	// 		// swiper.update();
		// 	// 		swiper.loopDestroy();
		// 	// 		// swiper.loopCreate();
		// 	// 	}, 1000),

		// slideChange: (swiper) => {
		// 	console.log("slideChange", swiper);
		// 	// swiper.slideTo;
		// },
		// 	// slideChangeTransitionStart(swiper) {
		// 	// 	swiper?.slideToLoop(swiper.realIndex, 0, false);
		// 	// },
		// 	slideChangeTransitionEnd(swiper) {
		// 		setSwiper(swiper);
		// 		swiper?.slideToLoop(swiper.realIndex, 0, false);
		// 	},
		// },
		...params,
	};

	// setTimeout(() => {
	// 	swiper?.loopDestroy();
	// 	swiper?.loopCreate();
	// });
	// swiper && swiper?.loopDestroy();
	// console.log("swiperInstance", swiper);

	return <DynamicSwiper {...defaultParams}>{children}</DynamicSwiper>;
};
