import * as React from "react";
import { FC, useEffect, useState } from "react";
import cn from "classnames";
import { Logo } from "../Logo/Logo";
import { IconButton } from "../../ui-kit/Button/IconButton";

import { useWindowSize } from "../../utils/useWindowSize";
import { Container } from "../../ui-kit/Container/Container";
import { useLocation } from "react-router-dom";


export const HEADER_NAV_ITEMS = [
	{
		label: "About the project",
		pathname: "/",
		key: "about",
	},
	{
		label: "For advertisers",
		pathname: "/sellers",
		key: "sellers",
	},
	{
		label: "For creators",
		pathname: "/creators",
		key: "creators",
	},
];

type HeaderProps = {
	[prop: string]: any;
};

export const Header: FC<HeaderProps> = ({ isFixedHeader }) => {
	const { isMobile } = useWindowSize();
	const location = useLocation();
	const [currentPageState, setCurrentPageState] = useState("/");
	useEffect(() => {
		setCurrentPageState(location.pathname);
	}, [location]);
	const [isSearchOpen, setSearchIsOpen] = useState(false);

	const toggleIsSearchOpenn = () => {
		setSearchIsOpen(!isSearchOpen);
	};
	// MOBILE
	const [isMobMenuOpen, setIsMobMenuOpen] = useState(false);
	const toggleIsMobMenuOpen = () => {
		setIsMobMenuOpen(!isMobMenuOpen);
	};
	const toggleMobMenu = () => {
		isMobile && isSearchOpen && toggleIsSearchOpenn();
		toggleIsMobMenuOpen();
		const body = document?.body;
		const classList = body && body.classList;
		const menuClass = "open-menu";
		isMobMenuOpen && classList
			? classList?.remove(menuClass)
			: classList?.add(menuClass);
	};

	console.log(currentPageState);
	return (
		<header
			style={
				isFixedHeader
					? {
						backgroundColor: "#0c0216",
					}
					: {}
			}
			className={cn("header", {
				"zIndex-1 ": currentPageState.includes("/sellers"),
				"bg-300": !currentPageState.includes("/sellers"),
			})}>
			<Container>
				<div className='header-left'>
					<Logo id='header-logo' />
					{(!isSearchOpen || isMobile) && (
						<div className='header-links flex1'>

							<div className='header-links-left'>
								{!isSearchOpen &&
									HEADER_NAV_ITEMS.slice(0, 3).map((item, i) => (
										<div
											className={cn("header-links__item", {
												"header-links__item--active": currentPageState === item.pathname,
											})}
											onClick={() => {
												isMobMenuOpen && toggleMobMenu();
											}}
											key={i}>
											<a href={item.pathname}>{item.label}</a>
										</div>
									))}
							</div>
							<div className='header-links-left'>
								{!isSearchOpen &&
									HEADER_NAV_ITEMS.slice(3, 6).map((item, i) => (
										<div
											className={cn("header-links__item", {
												"header-links__item--active": currentPageState === item.pathname,
												"mr-40 mr-m-0": i === 2,
											})}
											onClick={() => {
												isMobMenuOpen && toggleMobMenu();
											}}
											key={i}>
											<a href={item.pathname}>{item.label}</a>
										</div>
									))}
							</div>
						</div>
					)}
				</div>

				<div className='header-right'>
					{isMobile && (
						<IconButton
							icon={isMobMenuOpen ? <svg
								width='48'
								height='48'
								viewBox='0 0 48 48'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<g opacity='1'>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M16.7071 15.293L16 14.5859L14.5858 16.0002L15.2929 16.7073L22.5858 24.0002L15.2929 31.293L14.5858 32.0002L16 33.4144L16.7071 32.7073L24 25.4144L31.2929 32.7073L32 33.4144L33.4142 32.0002L32.7071 31.293L25.4142 24.0002L32.7071 16.7073L33.4142 16.0002L32 14.5859L31.2929 15.293L24 22.5859L16.7071 15.293Z'
										fill='white'
									/>
								</g>
							</svg> : <svg
								width='40'
								height='40'
								viewBox='0 0 40 40'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'>
								<path
									fill-rule='evenodd'
									clip-rule='evenodd'
									d='M9 16H8V18H9H31H32V16H31L9 16ZM9 22H8V24H9H31H32V22H31H9Z'
									fill='white'
								/>
							</svg>}
							onClick={toggleMobMenu}
							size={isMobile ? "xs" : "s"}
						/>
					)}
				</div>

			</Container>
		</header>
	);
};
