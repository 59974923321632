import cn from "classnames";
import { FC, createElement } from "react";

export type HeadingProps = {
	className?: string;
	text?: string;
	minLineHeight?: boolean;
	href?: string;
	children?: any;
	level: number;
};

export const Heading: FC<HeadingProps> = ({
	text,
	children,
	className,
	href = "",
	level,
	minLineHeight,
	...rest
}) => {
	const props = {
		className: cn(`H${level}`, className, {
			"H--minHeight": minLineHeight,
		}),
		...rest,
	};
	const content = text || children;

	return createElement(
		href ? "a" : `h${level}`,
		// @ts-ignore
		{ ...(href ? { href, ...props } : props) },
		!href && content,
		href && createElement(`h${level}`, {}, content),
	);
};
