import * as React from "react";
import cn from "classnames";


export enum ScreenSizeEnum {
	"xs" = "xs",
	"s" = "s",
	"m" = "m",
	"l" = "l",
	"xl" = "xl",
}



export interface IColProps {
	first?: ScreenSizeEnum;
	last?: ScreenSizeEnum;

	xsOffset?: number;
	smOffset?: number;
	mdOffset?: number;
	lgOffset?: number;
	xlOffset?: number;

	xs?: number | string;
	s?: number | string;
	m?: number | string;
	l?: number | string;
	xl?: number | string;

	[prop: string]: any;
}

const classMap: any = {
	xs: "col-xs",
	s: "col-s",
	m: "col-m",
	l: "col-l",
	xsOffset: "col-xs-offset",
	smOffset: "col-sm-offset",
	mdOffset: "col-md-offset",
	lgOffset: "col-lg-offset",
	xlOffset: "col-xl-offset",
};

export const rowKeys = [
	"start",
	"center",
	"end",
	"top",
	"middle",
	"bottom",
	"around",
	"between",
	"none",
	"xs",
	"sm",
	"md",
	"lg",
	"nowrap",
	"stretch",
];
// const screenSizeMap = ["xs", "s", "m", "l", ""];
const screenSizeMap = ["l", "m", "s", "xs"];

const getGapClassNames = ({
	size,
	prop,
}: {
	size: number[] | number;
	prop: string;
}) => {
	const modificators: any = {};

	if (Array.isArray(size)) {
		if (size.length < 4) {
			modificators[`${prop}-${size[0]}`] = true;
			modificators[`${prop}-m-${size[0]}`] = true;
		} else {
			for (let i = 0; i < size.length; ++i) {
				const gapSize = size[i];
				const screenSize = screenSizeMap[i];
				if (gapSize) {
					modificators[
						`${prop}${screenSize ? "-" + screenSize : ""}-${gapSize}`
					] = true;
				}
			}
		}
	} else {
		modificators[`${prop}-${size}`] = true;
	}

	return modificators;
};
function getColClassNames(props: any): any {
	const gapClassNames = {
		...(props?.rGap && getGapClassNames({ prop: "rGap", size: props?.rGap })),
		...(props?.cGap && getGapClassNames({ prop: "cGap", size: props?.cGap })),
	};
	let extraClasses: any = { ...gapClassNames };
	if (props.first) {
		extraClasses[`first-${props.first}`] = true;
	}
	if (props.last) {
		extraClasses[`last-${props.last}`] = true;
	}

	for (let i = 0; i < rowKeys.length; ++i) {
		const key = rowKeys[i]; // middle, between
		const value = props[key]; // {ScreenSizeEnum.xs}
		if (typeof value === "string") {
			if (Array.isArray(value)) {
				value.forEach((item: string) => {
					extraClasses[`${key}-${item}`] = true;
				});
			} else {
				extraClasses[`${key}-${value}`] = true;
			}
		} else if (typeof value === "boolean") {
			extraClasses[`${key}`] = true;
		}
	}
	Object.keys(props)
		.filter((key: string) => classMap[key])
		.forEach((key: string) => {
			extraClasses[classMap[key] + "-" + props[key]] = true;
		});

	return extraClasses;
}

export const Col: React.FC<IColProps> = ({
	children,
	className,
	style,
	as = "div",
	id,
	role = "",
	...rest
}) => {
	return React.createElement(
		as,
		{
			id,
			className: cn("col", className, {
				...getColClassNames(rest),
				//   ...createIndentMargin(rest),
				//   ...createIndentPadding(rest),
			}),
			style: style,

			...(role && { role }),
		},
		children,
	);
};
