import * as React from "react"
import "../../public/fonts.css";
import "./styles/App.scss"
import { lazy, Suspense } from "react";
import { Footer } from "../common/Footer/Footer";
import { Header } from "../common/Header/Header";
import { Route, BrowserRouter as Router, Routes, } from "react-router-dom";

const MainPage = lazy(() => import("../components/MainPage").then(({ MainPage }) => ({ default: MainPage })),);
const SellersPage = lazy(() => import("../components/sellers-page/SellersPage").then(({ SellersPage }) => ({ default: SellersPage })),);
const CreatorsPage = lazy(() => import("../components/creators-page/CreatorsPage").then(({ CreatorsPage }) => ({ default: CreatorsPage })),);


const App = () => {

	return (
		<Router>
			<Suspense
				fallback={null}
			>
				<Header />
				<Routes>
					<Route exact path="/sellers" element={<SellersPage />} />
					<Route exact path="/creators" element={<CreatorsPage />} />
					<Route exact path="*" element={<MainPage />} />
				</Routes>

				<Footer />
			</Suspense>
		</Router>
	);
};

export default App;
