import * as React from "react";
import { createElement, FC, ReactNode } from "react";
import cn from "classnames";


export enum ScreenSizeEnum {
	"xs" = "xs",
	"s" = "s",
	"m" = "m",
	"l" = "l",
	"xl" = "xl",
}



export type RowProps = {
	children: ReactNode;
	className?: string;

	reverse?: boolean;
	start?: ScreenSizeEnum;
	center?: ScreenSizeEnum | boolean;
	end?: ScreenSizeEnum;
	top?: ScreenSizeEnum | boolean;
	middle?: ScreenSizeEnum | boolean;
	bottom?: ScreenSizeEnum | boolean;
	around?: ScreenSizeEnum | boolean;
	between?: keyof typeof ScreenSizeEnum | boolean;
	stretch?: ScreenSizeEnum;
	onClick?: (e?: any) => void;
	row?: boolean;

	rGap?: number[] | number;
	cGap?: number[] | number;
	relative?: boolean;
	fullWidth?: boolean;

	[prop: string]: any;
};

export const rowKeys = [
	"start",
	"center",
	"end",
	"top",
	"middle",
	"bottom",
	"around",
	"between",
	"none",
	"xs",
	"sm",
	"md",
	"lg",
	"nowrap",
	"stretch",
];

// const screenSizeMap = ["xs", "s", "m", "l"];
const screenSizeMap = ["l", "m", "s", "xs"];

const getGapClassNames = ({
	size,
	prop,
}: {
	size: number[] | number;
	prop: string;
}) => {
	const modificators: any = {};

	if (Array.isArray(size)) {
		for (let i = 0; i < size.length; ++i) {
			const gapSize = size[i];
			const screenSize = screenSizeMap[i];
			if (gapSize) {
				modificators[`${prop}-${screenSize}-${gapSize}`] = true;
			}
		}
	} else {
		modificators[`${prop}-${size}`] = true;
	}

	return modificators;
};
function getRowClassNames(props: any): any {
	const gapClassNames = {
		...(props?.rGap && getGapClassNames({ prop: "rGap", size: props?.rGap })),
		...(props?.cGap && getGapClassNames({ prop: "cGap", size: props?.cGap })),
	};
	let modificators: any = { ...gapClassNames };

	for (let i = 0; i < rowKeys.length; ++i) {
		const key = rowKeys[i]; // middle, between
		const value = props[key]; // {ScreenSizeEnum.xs}
		if (typeof value === "string") {
			if (Array.isArray(value)) {
				value.forEach((item: string) => {
					modificators[`${key}-${item}`] = true;
				});
			} else {
				modificators[`${key}-${value}`] = true;
			}
		} else if (typeof value === "boolean") {
			modificators[`${key}`] = true;
		}
	}
	return modificators;
}

export const Row: FC<RowProps> = ({
	children,
	className,
	fullWidth,
	style = {},
	relative,
	row = true,
	as = "div",
	onClick,
	...rest
}) => {
	return createElement(
		as,
		{
			className: cn(className, {
				row: row,
				"width-100": fullWidth,
				relative: relative,
				...getRowClassNames(rest),
			}),
			style: style,
			onClick: (e: any) => onClick && onClick(e),
		},
		children,
	);
};
