import { useState } from "react";
import { useEventListener } from "./useEventListener";
import { useIsomorphicLayoutEffect } from "./useIsomorphicLayoutEffect";

interface WindowSize {
    width: number;
    height: number;
}

export const useWindowSize = () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<WindowSize>({
        width: 0,
        height: 0,
    });

    const handleResize = () => {
        // Set window width/height to state
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEventListener({
        target: "window",
        event: "resize",
        callback: handleResize,
    });

    // Set size at the first client-side load
    useIsomorphicLayoutEffect(() => {
        handleResize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        ...windowSize,

        isTablett:
            windowSize && windowSize?.width ? windowSize.width <= 1024 : false,
        isWidth1024:
            windowSize && windowSize?.width ? windowSize.width <= 1024 : false,
        isMobile:
            windowSize && windowSize?.width ? windowSize.width <= 1024 : false,
        isMobMenu:
            windowSize && windowSize?.width ? windowSize.width <= 1024 : false,
        isTablet: windowSize && windowSize?.width ? windowSize.width <= 768 : false,
        isWidth768:
            windowSize && windowSize?.width ? windowSize.width <= 768 : false,
    };
};
