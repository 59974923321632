import * as React from "react";
import { FC } from "react";

type LogoProps = {
	width?: string;
	id?: string;
};

export const Logo: FC<LogoProps> = (props) => {
	const { width, id = "logo" } = props;
	return (
		<a href='/' id={id}>
			<img src='/images/logo.svg' width={width} alt='Логотип' />
		</a>
	);
};
