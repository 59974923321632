import * as React from "react";
import cn from "classnames";
import { Button } from "../../ui-kit/Button/Button";
import { Container } from "../../ui-kit/Container/Container";
import { Col } from "../../ui-kit/Grid/Col";
import { Row } from "../../ui-kit/Grid/Row";
import { Heading } from "../../ui-kit/Typography/Heading";
import { useWindowSize } from "../../utils/useWindowSize";

export const CreatorsPage = () => {
    const imgPath = "/images/creators";
    const openSellerRegForm = () => {
        const win = window.open("https://creator.atips.io/sign_up", "_blank");
        win?.focus();
    };

    const { isTablet } = useWindowSize();

    const cabinetSectionItems = [
        "Uploading a finalized video or streaming live",
        "Best advertising suggestions",
        "Ready-to-use editing studio",
        "Broadcasts and streams records",
        "Detailed statistics to analyze your work",
    ];

    const possibilitiesSectionItems = [
        "Additional advertising revenue",
        "Monetizing advertising content in the Russian Federation and CIS countries",
        "New opportunities for collaboration with clothing manufacturers as early as at the filming stage",
        "A unique way to attract audience to content",
    ];
    return (
        <main className="main-home">
            <div className='sellers-page creators-page  mt-80 T15-bold'>
                <Row className='sellers-page__top-section mb-180 mb-m-64'>
                    <Container className=' w100'>
                        <div className='sellers-page__top-section-left'>
                            <Heading
                                level={isTablet ? 2 : 1}
                                text='Create more with Atips features'
                            />
                            <h5
                                className={cn("mv-32 mv-m-16", {
                                    H5: !isTablet,
                                })}>
                                Do you want to get a convenient advertising tool, complete freedom of expression and content management? For that, we will provide access to the blogger account at the Atips very soon.
                            </h5>

                            {/* <Row middle cGap={24}>
                                <Button
                                    text='Register'
                                    variant='primary'
                                    onClick={openSellerRegForm}
                                    className={cn({ "mb-m-16": isTablet })}
                                />

                                <p
                                    className={cn("opacity-7", {
                                        "T13-list": isTablet,
                                        "T14-list": !isTablet,
                                    })}>
                                    All you need to do is register<br />a blogger account — it’s free
                                </p>
                            </Row> */}
                        </div>
                    </Container>
                    <img
                        className='sellers-page__top-section-right'
                        src={`${imgPath}/top-section-img.png`}
                        alt='atips'
                        style={isTablet ? { left: "-40px", right: "-40p", width: "calc(100% + 80px)" } : {
                            width: "688px",
                            height: "688px"
                        }}
                    />
                </Row>

                <section className='creators-page__cabinet-section pb-160 pb-m-64'>
                    <Container>
                        <Heading
                            level={3}
                            text='Features provided by the account'
                            className='mb-40 mb-m-32'
                        />

                        {cabinetSectionItems.map((item, i) => (
                            <li key={i} className={`point-${i + 1}`}>
                                <img
                                    src={`${imgPath}/point${i + 1}.png`}
                                    alt='atips'
                                />

                                <h5
                                    className={cn({
                                        "mb-40": i !== 4,
                                    })}>
                                    {item}
                                </h5>
                            </li>
                        ))}
                    </Container>
                </section>

                <Container>
                    <section className='creators-page__capabilities-section mb-80 mb-m-48'>
                        <Heading
                            level={3}
                            text='Atips features'
                            className='mb-40 mb-m-32'
                        />

                        <Row rGap={16} cGap={24} fullWidth>
                            {possibilitiesSectionItems.map((item, i) => (
                                <li className='' key={i}>
                                    <p className='mh-32 mt-32 mb-8'>{item}</p>
                                    <div className='figure-wrap'>
                                        <img
                                            src={`${imgPath}/possibilities${i + 1}${isTablet ? "" : ""
                                                }.svg`}
                                            alt='atips'
                                        />
                                    </div>
                                </li>
                            ))}
                        </Row>
                    </section>
                </Container>
            </div>
        </main>
    );
};
