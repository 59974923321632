import * as React from "react";
import cn from "classnames";
import { FC, ButtonHTMLAttributes } from "react";
import { RadiusSize, SvgProps } from "../../types/shared"
import { BtnSize, BgType } from "./IconButton";

export const enum BtnVariantEnum {
	primary = "primary",
	secondary = "secondary",
}
type BtnVariant =
	| "primary"
	| "secondary"
	| "success"
	| "white"
	| "purple";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	className?: string;
	text?: any;
	size?: BtnSize;
	iconBefore?: React.ReactNode;
	iconAfter?: React.ReactNode;
	opacity?: 5;
	bg?: BgType;

	variant?: BtnVariant;

	isLoading?: boolean;
	radius?: RadiusSize;
	svgProps?: SvgProps;
};

export const Button: FC<ButtonProps> = (props) => {
	const {
		iconBefore,
		iconAfter,
		text,
		size = "m",
		variant,
		className,
		opacity,
		bg,
		isLoading,
		radius,
		svgProps = {},
		...rest
	} = props;

	const varClassMap: { [key in BtnVariant]: string } = {
		primary: "blue-button btn-primary",
		secondary: "ghost-button btn-secondary",
		tinkoff: "btn-tinkoff",
		success: "btn-success",
		white: "btn-white",
		purple: "btn-purple",
	};

	return (
		<button
			className={cn(
				"btn T15-bold",
				{
					// @ts-ignore
					[`${varClassMap[variant]}`]: variant,
					[`btn--${size}`]: size,
					[`opacity-${opacity}`]: opacity,
					[`bg--${bg} bg-${bg}`]: bg,
					"btn--loading": isLoading,
					[`radius-${radius}`]: radius,
				},
				className,
			)}
			{...rest}>
			<>
				{iconBefore}
				{text && text}
				{iconAfter}
			</>
		</button>
	);
};
